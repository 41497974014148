import { Box, CircularProgress } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import DashboardWrapper from "src/components/common/DashboardWrapper";
import DataGrid from "src/components/common/DataGrid";
import EmptyView from "src/components/common/EmptyView";
import { Button } from "src/components/common/FormComponents/Buttons";
import HorizontalTabs from "src/components/common/HorizontalTabs";
import {
  fundPaymentEntityTabs,
  fundPaymentTabs,
  fundPaymentTabsIO,
} from "src/constants/common";
import { getFundRequest } from "src/utils/api/createProjectFlow";
import {
  dateAndTimeStamp,
  downloadSheet,
  formatToIndianCurrency,
} from "src/utils/helper";
import defaultStyles from "./styles";
import { path } from "src/constants/path";
import SearchBar from "src/components/common/SearchField";
import { useAppSelector } from "src/redux/store";

const FundApplication = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const tab = searchParams.get("currentTab");

  const [tableRows, setTableRows] = useState([]);

  const [activeTab, setActiveTab] = useState(tab ?? undefined);

  const user = useAppSelector((state) => state.root.user);

  const role = user.data.userType;

  const [queryParams, setQueryParams] = useState<{
    status: string;
    offset: number;
    limit: number;
    download: boolean;
    sortBy: string;
    searchText: string;
  }>({
    status:
      role === "ORGANIZATION" ? "APPROVED,PAYMENT_ACKNOWLEDGED" : activeTab,
    offset: 0,
    limit: 500,
    download: false,
    sortBy: "updated_at:DESC",
    searchText: "",
  });

  const { isLoading, isFetching } = useQuery(
    ["users", queryParams],
    () =>
      getFundRequest({
        ...queryParams,
      }),
    {
      onSuccess: (res: AxiosResponse) => {
        const row = res?.data?.rows.map((row) => ({
          data: row,
          id: row.id,
          sno: row?.serialNo,
          costEstimateID: row?.landOwnershipEpid,
          registrationNo: row?.landOwnership?.projectDetail?.registrationNo,
          advanceWorkCost: formatToIndianCurrency(row?.advanceWorkCost),
          monitoringCost: formatToIndianCurrency(row?.monitoringCost),
          contingencyCost: formatToIndianCurrency(row?.contingencyCost),
          totalCost: formatToIndianCurrency(row?.totalCost),
          estimatedAdvanceWorkCost: formatToIndianCurrency(
            row?.estimatedAdvanceWorkCost
          ),
          estimatedMonitoringCost: formatToIndianCurrency(
            row?.estimatedMonitoringCost
          ),
          estimatedContingencyCost: formatToIndianCurrency(
            row?.estimatedContingencyCost
          ),
          totalEstimatedCost: formatToIndianCurrency(row?.totalEstimatedCost),
          ecoRestorationAmount:
            formatToIndianCurrency(row?.landOwnership?.demandNote) ?? "-",
          division:
            row?.landOwnership?.projectDetail?.landSpecification.division,
          entityName: row?.landOwnership?.user?.firstName,
          totalArea: `${row?.landOwnership?.netPlantationArea}`,
          remarks: row?.remark,
          updated_at: dateAndTimeStamp(row?.updated_at, "IST"),
          created_at: dateAndTimeStamp(row?.created_at, "IST"),
        }));

        if (queryParams.download) {
          downloadSheet(row);
          setQueryParams((prev) => ({
            ...prev,
            download: false,
          }));
        } else {
          setTableRows(row);
        }
      },
      onError: (err: AxiosError) => {},
      refetchOnMount: true,
    }
  );

  const RenderView = (params) => {
    return (
      <>
        <Box
          component="a"
          href={`${"/fund-application-details"}?${createSearchParams({
            paymentId: params.row.id,
          })}`}
          sx={{ ...defaultStyles.renderButtonText2 }}
        >{`${params?.row?.id}`}</Box>
      </>
    );
  };

  const RenderViewFundApplication = (params) => {
    console.log(params);
    return (
      <>
        {params?.row?.data?.status === "UNDER_QUERY" ||
        params?.row?.data?.status === "NODAL_UNDER_QUERY" ? (
          <Box
            component="a"
            href={`${"/view-fund-application"}?${createSearchParams({
              paymentId: params.row.id,
            })}`}
            sx={{ ...defaultStyles.renderButtonText2 }}
          >{`${params?.row?.data?.status}`}</Box>
        ) : (
          <Box sx={{ color: "red !important" }}>
            <Box sx={{ color: "red !important" }}>{"--"}</Box>
          </Box>
        )}
      </>
    );
  };

  const tableColumns: any = [
    {
      field: "sno",
      headerName: "S.No",
      width: 90,
      sortable: false,
      userType: ["INDIVIDUAL", null, "ORGANIZATION"],
      tab: [],
    },
    {
      field: "id",
      headerName: "Fund Application ID",
      width: 220,
      sortable: false,
      renderCell: RenderView,
      userType: ["INDIVIDUAL", null, "ORGANIZATION"],
      tab: [],
    },
    {
      field: "costEstimateID",
      headerName: "Cost Estimate ID",
      width: 220,
      sortable: false,
      userType: ["INDIVIDUAL", null, "ORGANIZATION"],
      tab: [],
    },
    {
      field: "registrationNo",
      headerName: "Reg.No",
      width: 100,
      sortable: false,
      userType: ["INDIVIDUAL", null, "ORGANIZATION"],
      tab: [],
    },
    {
      field: "entityName",
      headerName: "Entity Name",
      width: 150,
      sortable: true,
      userType: ["INDIVIDUAL", null, "ORGANIZATION"],
      tab: [],
    },
    {
      field: "division",
      headerName: "Division",
      width: 150,
      sortable: true,
      userType: ["INDIVIDUAL", "ORGANIZATION"],
      tab: [],
    },
    {
      field: "totalArea",
      headerName: "Net Planned Area(Ha)",
      width: 150,
      sortable: false,
      userType: ["INDIVIDUAL", null, "ORGANIZATION"],
      tab: [],
    },
    {
      field: "estimatedAdvanceWorkCost",
      headerName: "Estimated AdvanceWork Cost",
      width: 250,
      sortable: false,
      userType: ["INDIVIDUAL", null, "ORGANIZATION"],
      tab: [],
    },
    {
      field: "estimatedMonitoringCost",
      headerName: "Estimated Monitoring Cost",
      width: 200,
      sortable: false,
      userType: ["INDIVIDUAL", null, "ORGANIZATION"],
      tab: [],
    },
    {
      field: "estimatedContingencyCost",
      headerName: "Estimated Contingency Cost",
      width: 250,
      sortable: false,
      userType: ["INDIVIDUAL", null, "ORGANIZATION"],
      tab: [],
    },
    {
      field: "totalEstimatedCost",
      headerName: "Estimated Total Cost",
      width: 200,
      sortable: false,
      userType: ["INDIVIDUAL", null, "ORGANIZATION"],
      tab: [],
    },
    {
      field: "advanceWorkCost",
      headerName: "Advance Work Cost",
      width: 200,
      sortable: false,
      userType: ["INDIVIDUAL", null, "ORGANIZATION"],
      tab: [],
    },
    {
      field: "monitoringCost",
      headerName: "Monitoring Cost",
      width: 200,
      sortable: false,
      userType: ["INDIVIDUAL", null, "ORGANIZATION"],
      tab: [],
    },
    {
      field: "contingencyCost",
      headerName: "Contingency Cost",
      width: 200,
      sortable: false,
      userType: ["INDIVIDUAL", null, "ORGANIZATION"],
      tab: [],
    },
    {
      field: "totalCost",
      headerName: "Total Cost",
      width: 200,
      sortable: false,
      userType: ["INDIVIDUAL", null, "ORGANIZATION"],
      tab: [],
    },
    {
      field: "created_at",
      headerName: "Created At",
      width: 200,
      sortable: true,
      userType: ["INDIVIDUAL", null, "ORGANIZATION"],
      tab: [],
    },
    {
      field: "updated_at",
      headerName: "UPDATED AT",
      width: 200,
      sortable: true,
      userType: ["INDIVIDUAL", null, "ORGANIZATION"],
      tab: [],
    },
    {
      field: "underQuery",
      headerName: "Under Query",
      width: 220,
      sortable: false,
      renderCell: RenderViewFundApplication,
      userType: [null],
      tab: [],
    },
  ];

  const exportToExcelEntity = () => {
    setQueryParams({
      ...queryParams,
      download: true,
    });
  };

  const handleSort = (sortByField) => {
    setQueryParams({
      ...queryParams,
      sortBy: `${sortByField[0]?.field}:${sortByField[0]?.sort.toUpperCase()}`,
    });
  };

  const columns = useMemo(
    () =>
      tableColumns.filter((item) => {
        return item.userType.includes(role) && !!!item.tab.includes(activeTab);
      }),
    [queryParams]
  );

  const selectedTab =
    role === "INDIVIDUAL"
      ? fundPaymentTabs
      : role === "ORGANIZATION"
      ? fundPaymentEntityTabs
      : fundPaymentTabsIO;

  return (
    <DashboardWrapper
      title="Fund Application (FA)"
      buttonIcon="/assets/svg/roundPlusIcon.svg"
      buttonText=""
      onButtonClick={() => {}}
      showBtn={!!tableRows.length}
    >
      <>
        <Box>
          <SearchBar
            getText={(e) => {
              setQueryParams((a) => {
                return { ...queryParams, searchText: `${e}` };
              });
            }}
            customStyles={{
              wrapper: {
                width: "80%",
              },
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
              gap: "13%",
              padding: "10px 1%",
              background: "white",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
            }}
          >
            <HorizontalTabs
              tabHeaders={selectedTab}
              tabIndex={fundPaymentTabs.findIndex(
                (item) => item.value === activeTab
              )}
              onChange={(e) => {
                setActiveTab(e);
                navigate(
                  `${path.fundApplication}${e ? `?currentTab=${e}` : ""}`
                );
                setQueryParams((prev) => ({
                  ...prev,
                  status: e,
                }));
              }}
              components={[<></>, <></>]}
            />
            <Button onClick={exportToExcelEntity} text={"Export"} />
          </Box>
          {isLoading || isFetching ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : !!tableRows.length ? (
            <DataGrid
              rows={tableRows || []}
              columns={columns}
              rowCount={tableRows.length}
              rowsPerPageOptions={[10]}
              sortingMode="server"
              onSortModelChange={handleSort}
              paginationMode="client"
              pageSize={50}
              columnBuffer={20}
              loading={isLoading}
              getRowId={(row) => `${row.id}-${row.userName}`}
            />
          ) : (
            <EmptyView
              heading="No Entry Found"
              subheading=""
              onClick={() => {}}
              buttonText={""}
            />
          )}
        </Box>
      </>
    </DashboardWrapper>
  );
};

export default FundApplication;
