import { Box, CircularProgress } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { AxiosError, AxiosResponse } from "axios";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import DashboardWrapper from "src/components/common/DashboardWrapper";
import DataGrid from "src/components/common/DataGrid";
import EmptyView from "src/components/common/EmptyView";
import { Button } from "src/components/common/FormComponents/Buttons";
import HorizontalTabs from "src/components/common/HorizontalTabs";
import { registeredEntitiesTabs, statusMessages2 } from "src/constants/common";
import { getAllRegistration } from "src/utils/api/createProjectFlow";
import {
  dateAndTimeStamp,
  downloadSheet,
  formatToIndianCurrency,
} from "src/utils/helper";
import defaultStyles from "./styles";
import { path } from "src/constants/path";
import SearchBar from "src/components/common/SearchField";
import { useAppSelector } from "src/redux/store";

const Users = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const tab = searchParams.get("currentTab");

  const [tableRows, setTableRows] = useState([]);

  const [activeTab, setActiveTab] = useState(tab ?? undefined);

  const user = useAppSelector((state) => state.root.user);

  const role = user.data.userType;

  const [queryParams, setQueryParams] = useState<{
    status: string;
    offset: number;
    limit: number;
    download: boolean;
    sortBy: string;
    searchText: string;
  }>({
    status: activeTab,
    offset: 0,
    limit: 300,
    download: false,
    sortBy: "updated_at:DESC",
    searchText: "",
  });

  const { isLoading, isFetching } = useQuery(
    ["users", queryParams],
    () =>
      getAllRegistration({
        ...queryParams,
      }),
    {
      onSuccess: (res: AxiosResponse) => {
        const row = res?.data?.rows.map((row) => ({
          data: row,
          id: row.id,
          sno: row?.serialNo,
          status: statusMessages2[row.status] ?? row.status,
          registrationNo: row.registrationNo,
          state: row.state,
          district: row.district,
          range: row?.projectDetail?.landSpecification?.range,
          ecoRestorationAmount: formatToIndianCurrency(row?.demandNote) ?? "-",
          division: row.division,
          netPlantationArea: row?.netPlantationArea,
          entityName: row.user.firstName,
          projectType: row.status,
          projectRemarks: row.remarks,
          totalArea: `${row?.totalArea}`,
          netArea: row?.projectDetail?.landArea,
          remarks: row.remarks,
          updated_at: dateAndTimeStamp(row.updated_at, "IST"),
          remark: row.remark,
        }));

        if (queryParams.download) {
          downloadSheet(row);
          setQueryParams((prev) => ({
            ...prev,
            download: false,
          }));
        } else {
          setTableRows(row);
        }
      },
      onError: (err: AxiosError) => {},
      refetchOnMount: true,
    }
  );

  const RenderViewDemandDraft = (params) => {
    return (
      <>
        {params?.row?.status === "COST ESTIMATE PENDING" ||
        params?.row?.status === "RESUBMIT COST ESTIMATE" ||
        params?.row?.status === "ADMIN QUERY" ||
        params?.row?.status === "SNO QUERY" ? (
          <Box
            component="a"
            href={`${"/view-demand-draft"}?${createSearchParams({
              id: params.row.id,
            })}`}
            sx={{ ...defaultStyles.renderButtonText2 }}
          >{`${params?.row?.status}`}</Box>
        ) : (
          <Box sx={{ color: "red !important" }}>
            <Box sx={{ color: "red !important" }}>{"--"}</Box>
          </Box>
        )}
      </>
    );
  };

  const RenderUnderQuery = (params) => {
    return (
      <>
        {params?.row?.status === "ADMIN QUERY" ? (
          <Box
            component="a"
            href={`${"/view-demand-draft"}?${createSearchParams({
              id: params.row.id,
            })}`}
            sx={{ ...defaultStyles.renderButtonText2 }}
          >{`${params?.row?.status}`}</Box>
        ) : (
          <Box sx={{ color: "red !important" }}>
            <Box sx={{ color: "red !important" }}>{"--"}</Box>
          </Box>
        )}
      </>
    );
  };

  const fillFundApplication = (params) => {
    return (
      <>
        {params?.row?.status === "APPROVED" &&
        !params?.row?.data?.isFundApplicationSubmitted ? (
          <Box
            component="a"
            href={`${"/fill-fund-application"}?${createSearchParams({
              id: params.row.id,
            })}`}
            sx={{ ...defaultStyles.renderButtonText2 }}
          >
            {`Raise Fund Application`}
          </Box>
        ) : (
          <Box sx={{ ...defaultStyles.renderSubmitButton }}>
            Application Already Raised
          </Box>
        )}
      </>
    );
  };

  const VerifyDemandDraft = (params) => {
    return (
      <>
        {params?.row?.status === "SNO VERIFICATION PENDING" ? (
          <Box
            component="a"
            href={`${"/view-demand-draft"}?${createSearchParams({
              id: params.row.id,
            })}`}
            sx={{ ...defaultStyles.renderButtonText2 }}
          >{`${params?.row?.status}`}</Box>
        ) : (
          <Box sx={{ color: "red !important" }}>{"--"}</Box>
        )}
      </>
    );
  };

  const RenderView = (params) => {
    return (
      <>
        <Box
          component="a"
          href={`${"/landownership-details"}?${createSearchParams({
            id: params.row.id,
          })}`}
          sx={{ ...defaultStyles.renderButtonText2 }}
        >{`${params?.row?.id}`}</Box>
      </>
    );
  };

  const tableColumns: any = [
    {
      field: "sno",
      headerName: "S.No",
      width: 90,
      sortable: false,
      userType: ["INDIVIDUAL", null],
      tab: [],
    },
    {
      field: "id",
      headerName: "Cost Estimate ID",
      width: 200,
      sortable: false,
      renderCell: RenderView,
      userType: ["INDIVIDUAL", null],
      tab: [],
    },
    {
      field: "generateDemandNote",
      headerName: "Under Cost Estimate",
      width: 300,
      sortable: false,
      renderCell: RenderViewDemandDraft,
      userType: [null],
      tab: [
        "PAYMENT_PENDING",
        "NODAL_VERIFY_DEMAND_NOTE",
        "APPROVED,VERIFICATION_PENDING",
      ],
    },
    {
      field: "fundApplication",
      headerName: "Fill Fund Application",
      width: 290,
      sortable: false,
      renderCell: fillFundApplication,
      userType: [null],
      tab: [
        "PAYMENT_PENDING",
        "GENERATE_DEMAND_NOTE,REGENERATE_DEMAND_NOTE",
        "UNDER_QUERY",
        "NODAL_VERIFY_DEMAND_NOTE",
        "VERIFY_DEMAND_NOTE",
        "NODAL_UNDER_QUERY",
      ],
    },
    {
      field: "verifyDemandNote",
      headerName: "SNO Verification",
      width: 300,
      sortable: false,
      renderCell: VerifyDemandDraft,
      userType: ["INDIVIDUAL"],
      tab: [
        "PAYMENT_PENDING",
        "GENERATE_DEMAND_NOTE,REGENERATE_DEMAND_NOTE",
        "APPROVED,VERIFICATION_PENDING",
        "UNDER_QUERY",
        "VERIFY_DEMAND_NOTE",
        "NODAL_UNDER_QUERY",
      ],
    },
    {
      field: "Under Query",
      headerName: "Reply Query",
      width: 200,
      sortable: false,
      renderCell: RenderUnderQuery,
      userType: ["INDIVIDUAL"],
      tab: [
        "PAYMENT_PENDING",
        "GENERATE_DEMAND_NOTE,REGENERATE_DEMAND_NOTE",
        "APPROVED,VERIFICATION_PENDING",
        "VERIFY_DEMAND_NOTE",
        "NODAL_UNDER_QUERY",
        "NODAL_VERIFY_DEMAND_NOTE",
      ],
    },
    {
      field: "registrationNo",
      headerName: "Reg.No",
      width: 100,
      sortable: false,
      userType: ["INDIVIDUAL", null],
      tab: [],
    },
    {
      field: "entityName",
      headerName: "Entity Name",
      width: 200,
      sortable: true,
      userType: ["INDIVIDUAL", null],
      tab: [],
    },
    {
      field: "district",
      headerName: "District",
      width: 150,
      sortable: true,
      userType: ["INDIVIDUAL", null],
      tab: [],
    },
    {
      field: "range",
      headerName: "Range",
      width: 150,
      sortable: false,
      userType: [null],
      tab: [],
    },
    {
      field: "division",
      headerName: "Division",
      width: 150,
      sortable: true,
      userType: ["INDIVIDUAL"],
      tab: [],
    },
    {
      field: "netArea",
      headerName: "Total Area(Ha)",
      width: 180,
      sortable: false,
      userType: ["INDIVIDUAL", null],
      tab: [],
    },
    {
      field: "totalArea",
      headerName: "Selected Area(Ha)",
      width: 180,
      sortable: false,
      userType: ["INDIVIDUAL", null],
      tab: [],
    },
    {
      field: "netPlantationArea",
      headerName: "Final Area(Ha)",
      width: 180,
      sortable: false,
      userType: ["INDIVIDUAL", null],
      tab: ["GENERATE_DEMAND_NOTE,REGENERATE_DEMAND_NOTE"],
    },
    {
      field: "ecoRestorationAmount",
      headerName: "Eco-Restoration Amount",
      width: 200,
      sortable: false,
      userType: ["INDIVIDUAL", null],
      tab: [],
    },
    {
      field: "updated_at",
      headerName: "UPDATED AT",
      width: 250,
      sortable: true,
      userType: ["INDIVIDUAL", null],
      tab: [],
    },
    {
      field: "status",
      headerName: "Status",
      width: 300,
      sortable: false,
      userType: ["INDIVIDUAL", null],
      tab: [],
    },
    {
      field: "remark",
      headerName: "Remarks",
      width: 200,
      sortable: false,
      userType: ["INDIVIDUAL", null],
      tab: [],
    },
  ];

  const exportToExcelEntity = () => {
    setQueryParams({
      ...queryParams,
      download: true,
    });
  };

  const handleSort = (sortByField) => {
    setQueryParams({
      ...queryParams,
      sortBy: `${sortByField[0]?.field}:${sortByField[0]?.sort.toUpperCase()}`,
    });
  };

  const columns = useMemo(
    () =>
      tableColumns.filter((item) => {
        return item.userType.includes(role) && !!!item.tab.includes(activeTab);
      }),
    [queryParams]
  );

  return (
    <DashboardWrapper
      title="Cost Estimate"
      buttonIcon="/assets/svg/roundPlusIcon.svg"
      buttonText=""
      onButtonClick={() => {}}
      showBtn={!!tableRows.length}
    >
      <>
        <Box>
          <SearchBar
            getText={(e) => {
              setQueryParams((a) => {
                return { ...queryParams, searchText: `${e}` };
              });
            }}
            customStyles={{
              wrapper: {
                width: "80%",
              },
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
              gap: "13%",
              padding: "10px 1%",
              background: "white",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
            }}
          >
            <HorizontalTabs
              tabHeaders={registeredEntitiesTabs}
              tabIndex={registeredEntitiesTabs.findIndex(
                (item) => item.value === activeTab
              )}
              onChange={(e) => {
                setActiveTab(e);
                navigate(`${path.orderBook}${e ? `?currentTab=${e}` : ""}`);
                setQueryParams((prev) => ({
                  ...prev,
                  status: e,
                }));
              }}
              components={[<></>, <></>]}
            />
            <Button onClick={exportToExcelEntity} text={"Export"} />
          </Box>

          {isLoading || isFetching ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : !!tableRows.length ? (
            <DataGrid
              rows={tableRows || []}
              columns={columns}
              rowCount={tableRows.length}
              rowsPerPageOptions={[10]}
              sortingMode="server"
              onSortModelChange={handleSort}
              paginationMode="client"
              pageSize={50}
              columnBuffer={20}
              loading={isLoading}
              getRowId={(row) => `${row.id}-${row.userName}`}
            />
          ) : (
            <EmptyView
              heading="No Entry Found"
              subheading=""
              onClick={() => {}}
              buttonText={""}
            />
          )}
        </Box>
      </>
    </DashboardWrapper>
  );
};

export default Users;
